
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { EsService } from "@/services/es.service";
import { JnService } from "@/services/jn.service";
import { Utils } from "@/services/utils";
import JnProgress from "@/views/common/Progress.vue";
import { Picklist } from "@/models/picklist.model";
import { ValidationObserver } from "vee-validate";
import { SearchData } from "@/models/search.model";
import EventBus from "@/services/event.bus";
import { Categories } from "@/models/enums.model";

@Component({
  components: {
    JnProgress,
    ValidationObserver,
  },
})
export default class JnJuranewsSearch extends Vue {
  @Prop({ required: true })
  searchData?: SearchData;

  optText = "";
  optType = "AND";
  optAreeTematiche: Picklist[] = [];
  optMaterie: Picklist[] = [];
  optArgomenti: Picklist[] = [];
  optTags: Picklist[] = [];
  optTipoDocumento: Picklist[] = [];
  optFonti: Picklist[] = [];
  optTipoOrdinamento = "1";
  optNumRisultati = "20";

  tipiQuery: Picklist[] = [];
  areeTematiche: Picklist[] = [];
  materie: Picklist[] = [];
  argomenti: Picklist[] = [];
  tags: Picklist[] = [];
  tipiDocumento: Picklist[] = [];
  tipiOrdinamento: Picklist[] = [];
  numRisultati: Picklist[] = [];
  tipiFonte: Picklist[] = [];

  areeTematicheRaw!: any;
  tagsRaw!: any;

  alert = false;
  alertMessage = "";
  alertTimeout = 6000;

  advanced = false;
  loading = false;

  async switchAdvanced() {
    this.advanced = !this.advanced;
  }

  async search() {
    if (this.searchData) {
      this.searchData.keywords = []
      const optText = this.optText
        ? this.optText.replaceAll('/', '\\/')
        : this.optText;
      if (optText != "") {
        this.searchData.keywords.push(optText)
      }
      this.searchData.queryType = this.optType;
      if (this.optTipoOrdinamento) {
        this.searchData.orderingType = this.optTipoOrdinamento;
      }
      if (this.optNumRisultati) {
        this.searchData.perPage = Number(this.optNumRisultati);
      }

      const idAreeTematiche: string[] = [];
      for (const m of this.optAreeTematiche) {
        idAreeTematiche.push(m.value);
      }
      this.searchData.idAreaTematica = idAreeTematiche;

      const idMaterie: string[] = [];
      for (const m of this.optMaterie) {
        idMaterie.push(m.value);
      }
      this.searchData.idMateria = idMaterie;

      const idArgomenti: number[] = [];
      for (const a of this.optArgomenti) {
        idArgomenti.push(Number(a.value));
      }
      this.searchData.idArgomento = idArgomenti;

      const tags: string[] = [];
      for (const t of this.optTags) {
        tags.push(t.label);
      }
      this.searchData.tags = tags;

      const tipiDocumento: string[] = [];
      for (const td of this.optTipoDocumento) {
        tipiDocumento.push(td.value);
      }
      tipiDocumento.push(Categories.APPROFONDIMENTI);
      tipiDocumento.push(Categories.ULTIME_NEWS);

      this.searchData.idCategory = tipiDocumento;

      const idFonte: number[] = [];
      for (const f of this.optFonti) {
        idFonte.push(Number(f.value));
      }
      this.searchData.idFonte = idFonte;

      this.loading = true;
      const docs = await EsService.getDocs(this.searchData);
      if (docs.length == 0) {
        this.alert = true;
        this.alertMessage = "Nessun risultato trovato";
      }
      this.loading = false;
      //console.log(this.searchData);
      EventBus.$emit("search-data", this.searchData);
      EventBus.$emit("search-results", docs);

      this.$store.commit('searchData', this.searchData);
    }
  }

  clear() {
    this.optText = "";
    this.optType = "AND";
    this.optTipoOrdinamento = this.tipiOrdinamento[0].value;
    this.optNumRisultati = this.numRisultati[0].value;
    this.optAreeTematiche = [];
    this.optMaterie = [];
    this.optArgomenti = [];
    this.optTags = [];
    this.optTipoDocumento = [];
    this.optFonti = [];
  }

  close() {
    this.clear();
    this.advanced = false;
  }

  async created() {
    EventBus.$on("reset-ricerca-news", () => {
      this.search();
    });
    this.tipiQuery = JnService.getQueryTypes();
    this.tipiDocumento = JnService.getTipiDocumento();
    this.tipiOrdinamento = JnService.getTipiOrdinamento();
    this.optTipoOrdinamento = this.tipiOrdinamento[1].value;
    this.numRisultati = JnService.getNumeroRisultati();
    this.tipiFonte = await EsService.getPicklist("fonte");
    this.optNumRisultati = this.numRisultati[0].value;
    this.areeTematicheRaw = JSON.parse(
      await EsService.getOptions("struttura-aree-tematiche")
    );
    if (this.areeTematicheRaw && this.searchData) {
      for (const areaTematica of this.areeTematicheRaw["aree-tematiche"]) {
        this.areeTematiche.push(
          new Picklist({ label: areaTematica.name, value: areaTematica.id })
        );
        if (areaTematica.id == this.searchData.idAreaTematica) {
          for (const materia of areaTematica.materie) {
            this.materie.push(
              new Picklist({ label: materia.name, value: materia.id })
            );
          }
        }
      }
    }
    this.tagsRaw = JSON.parse(await EsService.getOptions("tags"));
    for (const tag of this.tagsRaw) {
      if (tag.value != "ROOT") {
        this.tags.push(new Picklist({ value: tag.id, label: tag.value }));
      }
    }
    this.tags.sort(Utils.sort("label", "asc"));
  }
}
